import type { UserRole } from '~/types/app.types';
import { USER_ROLES } from '~/types/app.types';

type PrivilegedRoles = Exclude<UserRole, 'customer'>;
const allowedRoles: PrivilegedRoles[] = USER_ROLES.filter((role) => role !== 'customer');

export default defineNuxtRouteMiddleware((to) => {
	const supabaseUser = useSupabaseUser();

	if (to.path === '/dashboard/confirm') return;

	if (
		!supabaseUser.value ||
		supabaseUser.value.aud !== 'authenticated' ||
		!allowedRoles.includes(supabaseUser.value.app_metadata.role)
	) {
		if (to.path === '/dashboard/login') {
			return;
		}
		return navigateTo('/dashboard/login');
	} else if (
		supabaseUser.value.aud === 'authenticated' &&
		allowedRoles.includes(supabaseUser.value.app_metadata.role) &&
		to.path === '/dashboard/login'
	) {
		return navigateTo('/dashboard');
	}
});
